import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'


const IndexPage = ({ pageContext: {exercise_number, title, python} }) => (
  <Layout>
    <h2>{exercise_number}. {title}</h2>
    <pre><code>{python}</code></pre>
    <h3>
      <Link to="/">
        <span role="img" aria-label="back">⬅️</span>
      </Link>
    </h3>
  </Layout>
)

export default IndexPage
